// Migrated
<template>
  <footer class="footer w-full px-4 mx-auto">
    <div class="flex flex-wrap -mx-4 h-full">
      <div class="xl:w-9/12 px-4 w-12/12 mx-auto h-full">
        <div class="flex flex-wrap -mx-4 h-full">
          <div class="xl:w-8/12 lg:w-7/12 md:w-7/12 px-4 flex flex-wrap -mx-4">
            <div
              v-for="(column, columnIndex) in footer"
              :key="columnIndex"
              class="footer-menu w-12/12 lg:w-6/12 px-4"
            >
              <div class="footer-heading mb-4">
                {{ column.label }}
              </div>
              <ul>
                <li
                  v-for="(columnItem, columnItemIndex) in column.links"
                  :key="columnItemIndex"
                >
                  <a
                    v-if="columnItem.absoluteLink"
                    :href="columnItem.link"
                    target="_blank"
                  >{{ columnItem.label }}</a>
                  <nuxt-link
                    v-else
                    :to="columnItem.link"
                  >
                    {{ columnItem.label }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="$route.name !== 'newsletter'"
            class="grow pb-6 footer-subscribe px-4"
          >
            <div
              class="footer-heading mb-4"
              data-i18n="newsletterSubscribe"
            >
              {{ $t('newsletterSubscribe') }}
            </div>
            <HydrateWhenVisible :class="[$isSol ? 'min-h-[200px]' : 'min-h-[135px]', $isSol ? 'md:min-h-[150px]' : 'md:min-h-[60px]']">
              <LazyNewsletterForm horizontal />
            </HydrateWhenVisible>
          </div>
        </div>
      </div>
      <div class="flex copyright justify-center w-full flex-col lg:flex-row">
        <div class="lg:mr-5 self-center">
          <p data-i18n="companyName">
            {{ $t('companyName') }}
          </p>
        </div>
        <div class="lg:mr-5 self-center">
          <p data-i18n="companyAddress">
            <fa
              class="mr-2"
              icon="map-marker-alt"
              width="11"
            />{{ $t('companyAddress') }}
          </p>
        </div>
        <div class="lg:mr-5 self-center">
          <a
            class="py-2 text-white"
            :href="`tel:${$t('companyPhone').replace(/[- ]/g, '')}`"
            data-i18n="companyPhone"
          >
            <fa
              class="mr-2"
              icon="phone"
              width="11"
            />{{ $t('companyPhone') }}</a>
        </div>
        <div class="self-center">
          <a
            class="py-2 text-white"
            :href="`mailto:${companyEmail}`"
            data-i18n="companyEmail"
          >
            <fa
              class="mr-2"
              icon="envelope"
              width="11"
            />{{ companyEmail }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const rootStore = useRootStore()
const localeStore = useLocaleStore()

const { footerLinks: footer } = storeToRefs(rootStore)
const companyEmail = computed(() => localeStore.getLocaleMessages.companyEmail)
</script>
